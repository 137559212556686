import React from "react"
import { ButtonWrapper } from "../elements"

export const Button = ({ children, href, ...rest }) => {
  return (
    <ButtonWrapper to={href} {...rest}>
      {children}
    </ButtonWrapper>
  )
}
