import styled from "styled-components"
import {
  getColor,
  getWeight,
  getFontSize,
  getLineHeight,
} from "../utils/style-config"

export const P = styled.p`
  margin: ${props => (props.margin ? props.margin : 0)};
  font-size: ${props => getFontSize(props)};
  line-height: ${props => getLineHeight(props)};
  text-decoration: ${props =>
    props.textDecoration ? props.textDecoration : "none"};

  font-weight: ${props => getWeight(props)};

  /* color: ${props => getColor(props)}; */

  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
`

export const H1 = styled.h1`
  font-size: 3.6rem;
  line-height: 4rem;
  /* color: ${props => getColor(props)}; */
  font-weight: 400;
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  margin: ${props => (props.margin ? props.margin : 0)};
`

export const H2 = styled.h2`
  font-size: 2.4rem;
  line-height: 3rem;
  /* color: ${props => getColor(props)}; */
  font-weight: 400;
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  margin: ${props => (props.margin ? props.margin : 0)};
`
