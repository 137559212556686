import styled from "styled-components"

export const ContentWrapper = styled.main`
  grid-column: 2 / span 12;
  grid-row: 2 / span 4;

  /* background-color: ${props => props.theme.colors.white1}; */
  /* background: ${props => props.theme.colors.base}; */
  /* padding: ${props =>
    `${props.theme.spacings.xSmall} ${props.theme.spacings.small}`}; */

  box-shadow: ${props => props.theme.shadows.shadow1};
  z-index: 10;

  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
  }
`
