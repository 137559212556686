import React from "react"
import { ContentWrapper } from "../elements"

export const Content = ({ children, style = {} }) => {
  return (
    <ContentWrapper
      className="testR1 bg-neutral text-neutral-content"
      style={{
        // boxShadow:
        //   "inset 3px 3px 5px rgba(0,128,128,.1), 3px 3px 5px rgba(0,128,128,.1)",
        borderRadius: "12px",
        overflow: "hidden",
        // background: "linear-gradient(to left, #514a9d25, #00808025)",
        ...style,
      }}
    >
      {children}
    </ContentWrapper>
  )
}
