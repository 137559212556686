export default {
  fonts: {
    main: "Poppins, sans-serif",
    code: "Poppins, sans-serif",
  },
  colors: {
    // shades of base color
    light1: "#1affff", //more light
    light2: "#00e5e6", //less light than light1

    base: "#008080", //main color

    dark1: "#80ffff", //more dark
    dark2: "#007f80", //less dark than dark1

    // for background white color
    white1: "#ffffff", //more white
    white2: "#ececec", //less white than white1,
    link: "oklch(var(--a))",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 76.8rem)",
    tablet: "only screen and (max-width: 99.2rem)",
  },
  spacings: {
    xxSmall: ".4rem",
    xSmall: ".8rem",
    small: "1.6rem",
    medium: "3.2rem",
    large: "4.8rem",
    xLarge: "6.4rem",
    xxLarge: "9.6rem",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "0px 5px 20px rgba(30,30,31,0.05)",
  },
}
