import React from "react"
import { CardWrapper, P, H2 } from "../elements"
import { Button } from "../components"
import { FeatureImage } from "./FeatureImage"
import { useWindowSize } from "../utils/useWindowSize"

export const ContentCard = ({ date, title, excerpt, slug, featureImage }) => {
  const [, size] = useWindowSize()

  const getExcerpt = () => {
    let cutLength = 511

    if (size < 1130 && size > 700) {
      cutLength = cutLength - parseInt((1130 - size) * 0.95, 10)
    } else if (size <= 700 && size > 550) {
      cutLength = 231
      cutLength = cutLength - parseInt((700 - size) * 0.95, 10)
    } else if (size <= 550) {
      cutLength = 151
      cutLength = cutLength - parseInt((550 - size) * 2.0, 10)
    }

    if (cutLength < 20) {
      cutLength = 20
    }

    return (excerpt || "").substring(0, cutLength)
  }
  return (
    <CardWrapper
      style={{
        // background: "#00000099",
        overflow: "hidden",
      }}
      className="testR1 bg-base-300 leading-normal"
    >
      {size > 700 && (
        <Button href={`/${slug}`} color="var(--base)">
          <div
            style={{
              width: "180px",
              height: "180px",
              // background: "#000000",
              marginRight: "24px",
              borderRadius: "4px",
            }}
            className="testR1 bg-base-300"
          >
            <FeatureImage
              fixed={featureImage}
              style={{
                height: "180px",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </Button>
      )}
      <Button href={`/${slug}`} color="dark2">
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "start",
            height: "180px",
            width: "100%",
          }}
          className="testR1"
        >
          <Button href={`/${slug}`} color="var(--base)">
            <H2
              textAlign="left"
              margin="0 0 1.6rem 0"
              style={
                {
                  // fontSize: desktop ? "var(--fsLarge)" : "var(--fsBase)",
                  // marginTop: "42px",
                }
              }
              className="text-base-content text-3xl lg:text-4xl leading-normal hover:text-accent"
            >
              {title}
            </H2>
          </Button>
          {/* <P size="xSmall" textAlign="center" margin="0 0 0.8rem 0" color="dark1">
          {date}
        </P> */}
          <div>
            {size > 370 ? (
              <P
                size="small"
                // color="dark2"
                textAlign="left"
                margin="0 0 2.4rem 0"
                className="text-base-content leading-normal hover:text-accent"
              >
                <h3>{getExcerpt()}...</h3>

                <Button
                  href={`/${slug}`}
                  // className="text-accent"
                  color="oklch(var(--a))"
                  $hoverColor="oklch(var(--a))"
                >
                  Read More
                </Button>
              </P>
            ) : (
              <Button
                href={`/${slug}`}
                color="oklch(var(--a))"
                $hoverColor="oklch(var(--a))"
              >
                Read →
              </Button>
            )}
          </div>
        </section>
      </Button>
    </CardWrapper>
  )
}
