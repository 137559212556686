const ALL_FREE_TOOLS = [
  {
    name: "Bulk Image Background Removal",
    link: "/tools/bulk-image-background-removal/",
    desc: "Remove backgrounds from multiple images with one click. Ideal for e-commerce product photos, marketing materials, social media content, and graphic design projects.",
    tags: ["remove", "background"],
    schema_markup: {
      name: "Bulk Image Background Removal",
      desc: "A free, efficient tool to remove backgrounds from multiple images with one click. Ideal for e-commerce product photos, marketing materials, social media content, and graphic design projects. Create professional-looking images quickly and easily.",
      featureList: [
        "Bulk background removal",
        "One-click operation",
        "Automatic subject detection",
        "Transparent background creation",
        "Suitable for various uses (e-commerce, marketing, social media, graphic design)",
        "High-quality output",
        "Preserves image edges",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      aggregateRating: {
        ratingValue: "4.7",
        ratingCount: "7",
        bestRating: "5",
        worstRating: "1",
      },
      version: "1.0",
    },
  },
  {
    name: "Bulk Image Background Blurring",
    link: "/tools/bulk-blur-image-background/",
    desc: "Blur backgrounds in multiple images with one click. Ideal for privacy protection, focusing on subjects, creating depth in photos, and enhancing visual appeal. Perfect for social media posts, professional headshots, event photography, and creative projects.",
    tags: ["blur", "background"],
    schema_markup: {
      name: "Bulk Image Background Blurring",
      desc: "A free, powerful tool to blur backgrounds in multiple images with one click. Adjust blur thickness for optimal effect. Ideal for privacy protection, focusing on subjects, creating depth in photos, and enhancing visual appeal. Perfect for social media posts, professional headshots, event photography, and creative projects.",
      featureList: [
        "Bulk background blurring",
        "One-click operation",
        "Adjustable blur thickness",
        "Automatic subject detection",
        "Background-only blurring",
        "Enhances subject focus",
        "Creates depth in photos",
        "Suitable for various uses (social media, professional headshots, event photography, creative projects)",
        "Quick and easy background obscuration",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },

  {
    name: "Bulk Image Blurring",
    link: "/tools/bulk-image-blurring/",
    desc: "Blur entire images with one click. Ideal for anyone needing to obscure photo content quickly and easily. Perfect for privacy protection, creating visual effects, or reducing distractions in images.",
    tags: ["blur"],
    schema_markup: {
      name: "Bulk Image Blurring",
      desc: "A free, efficient tool to blur entire images with one click. Adjust blur thickness for optimal obscuration. Ideal for anyone needing to obscure photo content quickly and easily. Perfect for privacy protection, creating visual effects, or reducing distractions in images.",
      featureList: [
        "Bulk image blurring",
        "One-click operation",
        "Adjustable blur thickness",
        "Entire image obscuration",
        "Suitable for various uses (privacy protection, visual effects, distraction reduction)",
        "Quick and easy content blurring",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },

  {
    name: "Bulk Transparent Image Maker",
    link: "/tools/bulk-transparent-image-maker/",
    desc: "Make images transparent with one click. Ideal for anyone needing to create images with transparent backgrounds quickly and easily. Perfect for e-commerce, graphic design, web development, and creating versatile visual content.",
    tags: ["transparent", "opacity"],
    schema_markup: {
      name: "Bulk Transparent Image Maker",
      desc: "A free, user-friendly tool to make multiple images transparent with one click. Adjust transparency levels from 1 to 100 for precise control. Ideal for creating images with transparent backgrounds quickly and easily. Perfect for e-commerce, graphic design, web development, and creating versatile visual content.",
      featureList: [
        "Bulk image transparency processing",
        "One-click operation",
        "Adjustable transparency levels (1-100)",
        "Create transparent backgrounds",
        "Suitable for various uses (e-commerce, graphic design, web development)",
        "Quick and easy transparency creation",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },

  {
    name: "Bulk Image Face Blurring",
    link: "/tools/bulk-image-face-blurring/",
    desc: "Blur all faces in images with one click. Ideal for anyone needing to anonymize photos quickly and easily. Perfect for social media posts, journalism, research, and personal privacy.",
    tags: ["blur", "face"],
    schema_markup: {
      name: "Bulk Image Face Blurring",
      desc: "A free, efficient tool to blur all faces in multiple images with one click. Adjust blur thickness for optimal anonymization. Ideal for anyone needing to anonymize photos quickly and easily. Perfect for social media posts, journalism, research, and personal privacy.",
      featureList: [
        "Bulk face blurring in multiple images",
        "One-click operation",
        "Adjustable blur thickness",
        "Automatic face detection",
        "Suitable for various uses (social media, journalism, research, personal privacy)",
        "Quick and easy anonymization",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },
  {
    name: "Bulk Image Upscaler",
    link: "/tools/bulk-image-upscaler/",
    desc: "Increase resolution and improve quality with just one click. Ideal for enhancing visuals, preparing images for print, improving digital art, and more.",
    tags: ["upscale"],
    schema_markup: {
      name: "Bulk Image Upscaler",
      desc: "A free, powerful tool to increase resolution and improve image quality with just one click. Ideal for enhancing visuals, preparing images for print, improving digital art, and more.",
      featureList: [
        "Bulk image upscaling",
        "One-click resolution increase",
        "Image quality improvement",
        "Suitable for print preparation",
        "Enhances digital art",
        "Efficient processing",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },
  {
    name: "Bulk Image Compressor",
    link: "/tools/bulk-image-compressor/",
    desc: "Quickly compress multiple images for free. Reduce file sizes for faster website loading, easier sharing, and storage savings. Your images stay private with in-browser processing.",
    tags: ["compress", "image"],
    schema_markup: {
      name: "Bulk Image Compressor",
      desc: "A free, efficient tool to quickly compress multiple images. Adjust compression quality, max width, or max height for optimal results. Reduce file sizes for faster website loading, easier sharing, and storage savings. Your images stay private with in-browser processing.",
      featureList: [
        "Bulk image compression",
        "Adjustable compression quality",
        "Customizable max width and height",
        "Reduced file sizes",
        "Faster website loading",
        "Easier image sharing",
        "Storage space savings",
        "Maintains image quality based on settings",
        "In-browser processing for privacy",
        "No login required",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      aggregateRating: {
        ratingValue: "5",
        ratingCount: "3",
        bestRating: "5",
        worstRating: "1",
      },
      version: "1.0",
    },
  },
  {
    name: "Bulk PDF to Image Converter",
    link: "/tools/bulk-pdf-to-images-converter/",
    desc: "Easily convert multiple PDF files to Images. Ideal for archiving, presentations, web publishing, and printing preparation. Compatible with all devices, including mobile phones and tablets.",
    tools: [
      {
        name: "PDF to PNG",
        link: "/tools/bulk-pdf-to-images-converter/pdf-to-png/",
      },
      {
        name: "PDF to JPG",
        link: "/tools/bulk-pdf-to-images-converter/pdf-to-jpg/",
      },
      {
        name: "PDF to WEBP",
        link: "/tools/bulk-pdf-to-images-converter/pdf-to-webp/",
      },
    ],
    tags: ["pdf"],
    schema_markup: {
      name: "Bulk PDF to Image Converter",
      desc: "A free, efficient tool to easily convert multiple PDF files to images. Ideal for archiving, presentations, web publishing, and printing preparation. Compatible with all devices, including mobile phones and tablets.",
      featureList: [
        "Bulk PDF to image conversion",
        "Multiple output image formats",
        "Maintain original PDF quality",
        "Mobile device compatibility",
        "Suitable for various use cases (archiving, presentations, web publishing, printing)",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["application/pdf", "image/*"],
      version: "1.0",
    },
  },
  {
    name: "Profile Pic Maker",
    link: "/tools/profile-pic-maker/",
    desc: "Create stunning profile pictures in seconds. Automatically detect faces, remove backgrounds, and add custom colors or gradients. Perfect for social media, professional networking, personal branding, and online dating profiles.",
    tags: ["PFP", "face", "dp", "display pic"],
    schema_markup: {
      name: "Profile Pic Maker",
      desc: "A free, user-friendly tool to create stunning profile pictures in seconds. Automatically detect faces, remove backgrounds, and add various background styles including patterns, professional designs, gradients, and solid colors. Choose from multiple shapes and padding options. Ideal for social media, professional networking, personal branding, and online dating profiles.",
      featureList: [
        "Automatic face detection",
        "Background removal",
        "Multiple background options:",
        "  - Patterns",
        "  - Professional designs",
        "  - Gradient backgrounds",
        "  - Solid color backgrounds",
        "Multiple shape options (square, circle, rounded, extra rounded)",
        "Adjustable padding",
        "Quick processing",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },
  {
    name: "Bulk Image Resizer",
    link: "/tools/bulk-image-resizer/",
    desc: "Resize multiple images instantly. Adjust dimensions by pixels or percentage, rotate images, and maintain aspect ratios with just a few clicks. Perfect for web layouts, social media, e-commerce, and digital marketing.",
    tags: ["resize", "crop"],
    schema_markup: {
      name: "Bulk Image Resizer",
      desc: "A free, efficient tool to resize multiple images instantly. Adjust dimensions by pixels or percentage, rotate images, and maintain aspect ratios with just a few clicks. Ideal for web layouts, social media, e-commerce, and digital marketing.",
      featureList: [
        "Bulk image resizing",
        "Resize by pixels or percentage",
        "Image rotation",
        "Aspect ratio maintenance",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },

  {
    name: "Bulk Image Cropper",
    link: "/tools/bulk-image-cropper/",
    desc: "Crop multiple images instantly. Adjust crop dimensions, apply preset ratios, or create custom crops with just one click. Perfect for social media profiles, product photography, web layouts, and digital marketing campaigns.",
    tags: ["crop", "resize"],
    schema_markup: {
      name: "Bulk Image Cropper",
      desc: "A free, privacy-friendly bulk image cropping tool. Crop multiple images instantly with customizable dimensions, preset ratios, manual cropping, and smart content-aware cropping. Perfect for social media profiles, product photography, web layouts, and digital marketing campaigns.",
      featureList: [
        "Bulk image cropping",
        "Custom crop dimensions",
        "Preset aspect ratios",
        "Manual cropping option",
        "Smart content-aware cropping",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },
  {
    name: "Bulk Flip Image",
    link: "/tools/bulk-flip-image/",
    desc: "Flip multiple images horizontally or vertically with just one click. Create mirror effects, fix image orientations, or prepare graphics for various design needs.",
    tags: ["flip"],
    schema_markup: {
      name: "Bulk Flip Image",
      desc: "A free, user-friendly tool to flip multiple images horizontally or vertically with a single click. Create mirror effects, fix image orientations, or prepare graphics for various design needs.",
      featureList: [
        "Bulk image flipping",
        "Horizontal and vertical flipping options",
        "One-click operation",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },
  {
    name: "Face Analyzer: Explore Facial Geometry",
    link: "/tools/face-analyzer-score/",
    desc: "Quickly analyze facial symmetry and proportions. Calculate a face score based on detailed landmarks analysis for various features including jaw, nose, mouth, and eyes. Your images stay private with in-browser processing.",
    tags: [],
    hide_footer: true,
    hide_suggestion: true,
    schema_markup: {
      name: "Face Analyzer",
      desc: "A free, efficient tool to analyze facial symmetry and proportions. Calculate detailed scores for various features including jaw, nose, mouth, and eyes. Your images stay private with in-browser processing.",
      featureList: [
        "Facial symmetry analysis",
        "Proportion score calculation",
        "Detailed landmarks analysis",
        "In-browser processing for privacy",
        "No login required",
        "Free to use",
      ],
      fileFormat: ["image/jpeg", "image/png", "image/webp"],
      version: "1.0",
    },
  },

  {
    name: "Bulk Image Converter",
    link: "/tools/bulk-image-converter/",
    desc: "Easily convert multiple images between formats. Ideal for web optimization, social media sharing, storage management, and design work. Compatible with all devices, including mobile phones and tablets.",
    tools: [
      {
        name: "Convert to PNG",
        link: "/tools/bulk-image-converter/convert-to-png/",
      },
      {
        name: "Convert to JPG",
        link: "/tools/bulk-image-converter/convert-to-jpg/",
      },
      {
        name: "Convert to WEBP",
        link: "/tools/bulk-image-converter/convert-to-webp/",
      },
    ],
    tags: ["image", "convert", "png", "jpg", "webp"],
    schema_markup: {
      name: "Bulk Image Converter",
      desc: "A free, efficient tool to easily convert multiple images between formats. Ideal for web optimization, social media sharing, storage management, and design work. Compatible with all devices, including mobile phones and tablets.",
      featureList: [
        "Bulk image format conversion",
        "Multiple input and output image formats",
        "Maintain original image quality",
        "Mobile device compatibility",
        "Suitable for various use cases (web optimization, social media, storage management, design work)",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/png", "image/jpeg", "image/webp"],
      version: "1.0",
    },
  },
  {
    name: "Bulk PDF Image Extractor",
    link: "/tools/bulk-pdf-image-extractor/",
    desc: "Easily extract images from multiple PDF files. Ideal for content creation, research, web publishing, and digital asset management. Compatible with all devices, including mobile phones and tablets.",
    tags: ["pdf"],
    schema_markup: {
      name: "Bulk PDF Image Extractor",
      desc: "A free, efficient tool to easily extract images from multiple PDF files. Ideal for content creation, research, web publishing, and digital asset management. Compatible with all devices, including mobile phones and tablets.",
      featureList: [
        "Bulk image extraction from PDFs",
        "Extracts images in original formats",
        "Maintain original image quality",
        "Mobile device compatibility",
        "Suitable for various use cases (content creation, research, web publishing, digital asset management)",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["application/pdf"],
      version: "1.0",
    },
  },
  {
    name: "Bulk Book Summarizer (Alpha)",
    link: "/tools/bulk-book-summarizer/",
    desc: "Easily summarize multiple books at once. Ideal for research, personal development, book clubs, and content creation. Compatible with all devices, including mobile phones and tablets.",
    tags: ["books", "summarizer", "pdf"],
    hide_footer: true,
    hide_suggestion: true,
    hide: true,
    schema_markup1: {
      name: "Bulk Book Summarizer",
      desc: "A free, efficient tool to easily summarize multiple books at once. Ideal for research, personal development, book clubs, and content creation. Compatible with all devices, including mobile phones and tablets.",
      featureList: [
        "Bulk book summarization",
        "Generates concise summaries",
        "Maintains key concepts and ideas",
        "Mobile device compatibility",
        "Suitable for various use cases (research, personal development, book clubs, content creation)",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["application/pdf"],
      version: "1.0",
    },
  },
  {
    name: "Bulk Image Placeholder Generator",
    link: "/tools/bulk-image-placeholder-generator/",
    desc: "Generate lightweight placeholders for multiple images instantly. Create efficient, low-resolution previews that load quickly and improve user experience. Perfect for web developers, designers, and anyone looking to optimize image loading on websites or applications.",
    tags: ["placeholder", "preview"],
    schema_markup: {
      name: "Bulk Image Placeholder Generator",
      desc: "A free, efficient tool to generate lightweight placeholders for multiple images instantly. Create low-resolution previews that load quickly, improve perceived page load times, and enhance user experience. Ideal for web developers, designers, and anyone looking to optimize image loading on websites or applications.",
      featureList: [
        "Bulk placeholder generation",
        "Lightweight image previews",
        "Faster page loading",
        "Image Bandwidth optimization",
        "No login required",
        "Privacy-friendly (browser-based processing)",
        "Free to use",
      ],
      fileFormat: ["image/*"],
      version: "1.0",
    },
  },
]

const AllFreeTools = ALL_FREE_TOOLS
module.exports = AllFreeTools
