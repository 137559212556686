import "./src/styles/global.css"
import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"

import "./language-tabs.css"
import { Seo } from "./src/components"
import AllFreeTools from "./AllFreeTools_Copy"
import { NEW_DOMAIN_TOOLS } from "./src/utils/constants"
import { __ENV_DEV__ } from "./src/utils/__ENV_DEV__"
// Highlighting for code blocks
// import("prismjs/themes/prism.css")
// import("prismjs/themes/prism-solarizedlight.css")
// import("prismjs/themes/prism-coy.css")
import("prismjs/themes/prism-tomorrow.css")

const GlobalStyles = createGlobalStyle`
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body, html{
  font-family: ${props => props.theme.fonts.main};
  height: 100%;
  /* background-color: ${props => props.theme.colors.white2}; */
}
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <Seo />
    {element}
  </ThemeProvider>
)
export const onInitialClientRender = () => {
  document.getElementById("___loader").style.display = "none"

  addAff()
  // for impact.com verification
  AddImpact()

  addLmnSqBuyOverlay()
}

const addAff = () => {
  window.lemonSqueezyAffiliateConfig = { store: "charm-shot-ai" }
  const script = document.createElement("script")
  // script.src = "https://lmsqueezy.com/affiliate.js"
  script.src = "/lmsq-affiliate.js"
  script.defer = true
  document.head.appendChild(script)
}

const addLmnSqBuyOverlay = () => {
  const script = document.createElement("script")
  script.src = "/lmsq-buy-overlay.js"
  script.async = true
  script.defer = true
  document.head.appendChild(script)
}

const AddImpact = () => {
  const meta = document.createElement("meta")
  // Set the name and content attributes
  meta.setAttribute("name", "impact-site-verification")
  meta.setAttribute("content", "d23db4a1-5242-46ec-9401-dd85d0491ca5")
  document.head.appendChild(meta)
}

// In gatsby-browser.js
// don't touch this code, it's used for redirect
// don't use onPreRouteUpdate, because it runs before seo components get dynamic props
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== "undefined") {
    let locPathname = ""

    if (typeof window === "object") {
      locPathname = window.location.pathname || ""
      locPathname = locPathname.replace(/\/+$/, "")

      locPathname = !!locPathname ? locPathname : "/"
    }

    let toolLinkRedirect = ""

    AllFreeTools.map(toolObj => {
      // do not suggest the same tool on the same tool page
      const isSameTool =
        typeof window === "object" &&
        locPathname.includes((toolObj.link || "").replace(/\/+$/, ""))
      // console.log({ isSameTool })

      if (isSameTool) {
        toolLinkRedirect = toolObj.link
      }
    })

    let finalToolsUrl =
      locPathname &&
      toolLinkRedirect &&
      locPathname.startsWith(toolLinkRedirect.replace(/\/+$/, ""))
        ? locPathname
        : null
    if (locPathname === "/tools" && !finalToolsUrl) {
      finalToolsUrl = locPathname
    }
    finalToolsUrl = finalToolsUrl ? `${NEW_DOMAIN_TOOLS}${finalToolsUrl}` : null

    if (!finalToolsUrl) {
      return
    }
    // console.log({ finalToolsUrl, locPathname })
    // TODO-uncomment this
    if (!__ENV_DEV__) {
      window.location.href = finalToolsUrl
    }
  }
}
