import styled from "styled-components"

export const FooterWrapper = styled.footer`
  grid-column: 2 / span 12;
  min-height: 18rem;
  padding: 2rem 0rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  /* background-color: #cccccc; */

  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
  }
`

export const FooterSocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: start;
  /* text-align: center; */

  /* & p {
    text-align: center;
    flex: 0 0 100%;
  } */
`

export const FooterSocialIcons = styled.div`
  flex: 0 0 100%;
  margin-bottom: 3.2rem;

  & img {
    height: 22px;
    padding-right: 1.6rem;
    transition: filter 0.3s ease;
  }

  & img:hover,
  img:focus {
    transform: scale(1.05);
  }
`
