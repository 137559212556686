import { useLayoutEffect, useState, useEffect } from "react"

//mobile < 768px, tablet < 992px

export const useWindowSize = () => {
  const [screen, setScreen] = useState([false, false, true]) //[mobile, tablet, desktop]
  const [size, setSize] = useState(1000) //default init window width

  useLayoutEffect(() => {
    const updateSize = () => {
      let type
      if (window.innerWidth < 768) {
        // mobile
        type = [true, false, false]
      } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
        // tablet
        type = [false, true, false]
      } else {
        // desktop
        type = [false, false, true]
      }
      setScreen(type)
      setSize(window.innerWidth)
    }
    window.addEventListener("resize", updateSize)
    updateSize()

    return () => window.removeEventListener("resize", updateSize)
  }, [])

  useEffect(() => {
    let type
    if (screen[0]) {
      type = "mobile"
    } else if (screen[1]) {
      type = "tablet"
    } else if (screen[2]) {
      type = "desktop"
    }
    // console.log("screen: ", type, ", size: ", size)
  })

  return [screen, size]
}
