import styled from "styled-components"

export const CardWrapper = styled.section`
  margin: 1.6rem 0;
  padding: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  /* box-shadow:
    inset 1px 1px 2px rgba(0, 128, 128, 0.3),
    1px 2px 2px rgba(0, 128, 128, 0.3); */

  
  /* &:hover { */
    /* border: 0.1rem solid ${props => props.theme.colors.light1}; */ * /;
  /* box-shadow: none; */
  /* box-shadow:
    inset 3px 3px 5px rgba(0, 128, 128, 0.5),
    3px 3px 5px rgba(0, 128, 128, 0.5); */

  /* box-shadow: none;  */
  /* } */
`
