import React from "react"
import { ContainerWrapper } from "../elements"
import { Nav, Footer } from "../components"
import styled from "styled-components"

const Header = styled.header`
  grid-column: 1 / -1;
  grid-row: 1;
  width: 100%;
  padding: 0 calc((100% - (12 * 6.72rem + 11 * 3.8rem)) / 2);

  @media ${props => props.theme.breakpoints.tablet} {
    padding: 0 0.6rem;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 0 0.01rem;
  }
`

export const Container = ({ children, ...rest }) => {
  return (
    <ContainerWrapper
      style={
        {
          // background: "linear-gradient(to left, ##121212, #008080)",
        }
      }
      {...rest}
    >
      <Header>
        <Nav />
      </Header>

      {children}
      <Footer />
    </ContainerWrapper>
  )
}
