import React from "react"
import { PaginationWrapper, PaginationElement } from "../elements"

export const Pagination = ({ isFirst, isLast, prevPage, nextPage }) => {
  return (
    <PaginationWrapper isFirst={isFirst} isLast={isLast}>
      {prevPage && (
        <PaginationElement
          to={prevPage}
          className={` ${isFirst ? "text-neutral-content" : "text-accent"}`}
        >
          Previous Page
        </PaginationElement>
      )}
      {nextPage && (
        <PaginationElement
          to={nextPage}
          className={` ${isLast ? "text-neutral-content" : "text-accent"}`}
        >
          Next Page
        </PaginationElement>
      )}
    </PaginationWrapper>
  )
}
