import styled from "styled-components"

export const PostWrapper = styled.main`
  max-width: 100%;
  grid-column: 2 / span 12;
  grid-row: 3 / span 5;
  /* background-color: ${props => props.theme.colors.white1}; */
  /* background: ${props => props.theme.colors.base}; */
  padding: ${props =>
    `${props.theme.spacings.medium} ${props.theme.spacings.small}`};

  box-shadow: ${props => props.theme.shadows.shadow1};
  z-index: 10;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* color: ${props => props.theme.colors.dark2}; */
  }

  h1:not(:first-child),
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 3.2rem;
  }

  h1 {
    /* font-size: 3.2rem; */
    font-size: 2.2rem;
    line-height: 4rem;
    font-weight: 400;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 400;
  }

  h3 {
    font-size: 1.9rem;
    line-height: 2.6rem;
    font-weight: 400;
  }

  h4 {
    font-size: 1rem;
    line-height: 2.4rem;
    font-weight: 400;
  }

  h5 {
    font-size: 1.3rem;
    line-height: 2.2rem;
    font-weight: 700;
  }

  p {
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: 400;
    /* color: ${props => props.color || props.theme.colors.dark1}; */
    margin-top: 3.2rem;
  }

  a {
    color: ${props => props.theme.colors.link};
    text-decoration: underline;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  del {
    text-decoration: line-through;
  }

  blockquote p {
    font-style: italic;
    font-size: 2.4rem;
    line-height: 3.4rem;
    text-align: center;
    max-width: 57.6rem;
    margin: 4.8rem auto;
  }

  ul,
  ol {
    /* color: ${props => props.theme.colors.dark2}; */
    margin: 1.6rem 0 1.6rem 3.2rem;
  }
  li {
    margin: 0.4rem 0;
  }

  code {
    font-family: ${props => props.theme.fonts.code};
    font-size: 1.6rem;
    line-height: 3rem;
    /* TODO- fix these colors */
    color: ${props => props.theme.colors.dark3};
    background-color: ${props => props.theme.colors.dark3};
    padding: 0 0.48rem;
  }

  hr {
    border: 0;
    height: 1px;
    background: ${props => props.theme.colors.light1};
    opacity: 0.1;
    margin-top: 3.2rem;
  }

  table {
    width: 100%;
    border-spacing: 0.4rem;
    border-collapse: collapse;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
  }

  th {
    font-weight: 700;
  }
  table,
  th,
  td {
    border: 1px solid ${props => props.theme.colors.light1};
  }

  th,
  td {
    text-align: left;
    padding: 0.8rem;
  }

  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
  }

  /* @media ${props => props.theme.breakpoints.mobile} {
    padding: ${props =>
    `${props.theme.spacings.medium} ${props.theme.spacings.large}`};
  } */
`
