import React from "react"
import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
import { FeatureImageWrapper } from "../elements"

export const FeatureImage = ({ fixed, style, ...rest }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     imageSharp(fixed: { originalName: { eq: "office.jpg" } }) {
  //       fixed {
  //         ...GatsbyImageSharpFixed
  //       }
  //     }
  //   }
  // `)

  return (
    <FeatureImageWrapper {...rest}>
      <Img
        // fixed={fixed ? fixed : data.imageSharp.fixed}
        fixed={fixed}
        style={
          style
            ? { ...style }
            : {
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }
        }
        imgStyle={style || {}}
      />
    </FeatureImageWrapper>
  )
}
