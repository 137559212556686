import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const ButtonWrapper = styled(props => <Link {...props} />)`
  color: ${props => props.color || props.theme.colors.link};
  font-weight: 700;
  transition: filter 0.3s ease;
  text-decoration: none;

  &:hover,
  &:focus {
    filter: brightness(110%);
    color: ${props => props.$hoverColor || props.theme.colors.light1};
  }
`
