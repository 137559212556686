import React from "react"
import { PostWrapper } from "../elements"

export const Post = ({ children, ...rest }) => {
  return (
    <PostWrapper {...rest} className="bg-neutral text-neutral-content">
      {children}
    </PostWrapper>
  )
}
