export const getColor = props => {
  switch (props.color) {
    case "light1":
      return props.theme.colors.light1
    case "light2":
      return props.theme.colors.light2
    case "base":
      return props.theme.colors.light2
    case "dark1":
      return props.theme.colors.dark1
    case "dark2":
      return props.theme.colors.dark2
    case "white1":
      return props.theme.colors.white1
    case "white2":
      return props.theme.colors.white2

    default:
      return props.theme.colors.base
  }
}
export const getWeight = props => {
  switch (props.weight) {
    case "normal":
      return 400
    case "bold":
      return 700
    default:
      return 400
  }
}

export const getFontSize = props => {
  switch (props.size) {
    case "medium":
      return "1.8rem"
    case "small":
      return "1.6rem"
    case "xSmall":
      return "1.4rem"
    default:
      return "1.8rem"
  }
}

export const getLineHeight = props => {
  switch (props.size) {
    case "medium":
      return "2.3rem"
    case "small":
      return "2.2rem"
    case "xSmall":
      return "1.8rem"
    default:
      return "2.3rem"
  }
}
