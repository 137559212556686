import React, { useState } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { NavWrapper } from "../elements"
import { useWindowSize } from "../utils/useWindowSize"
import { useLocation } from "@reach/router"

// const PRODUCT_HUNT_URL = "https://www.producthunt.com/posts/charmshotai"

export const Nav = props => {
  const location = useLocation()
  const [mobile, , desktop] = useWindowSize()[0]
  const size = useWindowSize()[1]

  const [showMenu, setShowMenu] = useState(false)

  // const data = useStaticQuery(graphql`
  //   query {
  //     logo: file(relativePath: { eq: "logo.png" }) {
  //       publicURL
  //     }
  //   }
  // `)

  const isPricingPage = (location.pathname || "")
    .toLowerCase()
    .includes("/pricing")

  const isHomePage = (location.pathname || "") === "/"

  // console.log(location)

  return (
    <NavWrapper
      className="testR1 sticky top-0 z-50 bg-neutral w-full"
      style={{
        // margin: "12px 0px",
        // lineHeight: "26px",
        // ...(mobile && { textAlign: "left" }),
        // height: "100px",
        color: "oklch(var(--bc))",
        // ...(isPricingPage && {
        height: "60px",
        // }),
      }}
    >
      {/* sticky product hunt banner */}
      {/* {PRODUCT_HUNT_URL && (
        <div
          className="absolute top-0 left-0 right-0 text-center text-2xl cursor-pointer"
          onClick={() => {
            Object.assign(document.createElement("a"), {
              target: "_blank",
              rel: "noopener noreferrer",
              href: `${PRODUCT_HUNT_URL}`,
            }).click()
          }}
        >
          <div className="bg-[red]">Live on Product Hunt</div>
        </div>
      )} */}
      {/* logo */}
      {/* <Link
        to="/"
        className="testBlu1"
        style={{
          textDecoration: "none",
          fontSize: "var(--fsBase)",

          width: "100%",
          // fontWeight: "bolder",
          marginLeft: "22px",
        }}
      > */}
      <a
        href="/"
        target="_self"
        style={{
          textDecoration: "none",
          fontSize: "var(--fsBase)",

          width: "100%",
          // fontWeight: "bolder",
          marginLeft: "22px",
        }}
        className="hover:text-blue-500 cursor-pointer"
      >
        <span>CharmShotAI</span>
        {/* {!isHomePage && !isPricingPage && (
          <span className="animate-ping inline-flex rounded-full h-3 w-3 opacity-75 -mr-3 mt-1 absolute bg-primary">
            &nbsp;
          </span>
        )} */}
      </a>
      {/* </Link> */}
      {/* other nav items */}
      {/* TODO-add hamburger icon */}

      <div
        style={{
          width: "100%",
          // maxWidth: "75%",
          display: "flex",
          justifyContent: "end",
          fontSize: desktop ? "var(--fsBase)" : "var(--fsSmall)",
          // ...(mobile && { display: "none" }),
          marginRight: "22px",
          alignItems: "center",
        }}
        className="testO1"
      >
        {/* {!mobile && !isPricingPage && (
          <div className="mr-10 relative">
            <a
              href="/pricing/#testimonial"
              target="_self"
              style={{
                textDecoration: "none",
              }}
            >
              Testimonials
            </a>
          </div>
        )} */}
        {/* <div className="mr-10 relative">
          <a
            href="https://billing.charmshotai.com/affiliates"
            target="_self"
            style={{
              textDecoration: "none",
            }}
          >
            Affiliate
          </a>
        </div> */}
        {!isPricingPage && (
          <div className="mr-10 relative">
            <a
              href="/pricing"
              target="_self"
              style={{
                textDecoration: "none",
              }}
            >
              Pricing
            </a>

            <span className="animate-ping inline-flex rounded-full h-3 w-3 opacity-75 -mr-3 mt-3 absolute bg-primary">
              &nbsp;
            </span>
          </div>
        )}
      </div>
    </NavWrapper>
  )
}
